import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TenantDashboard } from '@api/index';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { getRandomId, tryParseJSON, validateJSON } from '../../extensions/string.extensions';
import { TranslationService } from '../../transloco/services/translation.service';
import { AiAssistantDialogComponent } from './ai-assistant-dialog/ai-assistant-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class AiAssistantService {
    public ngbModalService = inject(NgbModal);
    private router = inject(Router);
    private translationService = inject(TranslationService);
    private message_: unknown;

    public get message(): unknown {
        return this.message_;
    }

    public async open() {
        this.ngbModalService.dismissAll();
        const modalRef = this.ngbModalService.open(AiAssistantDialogComponent);

        try {
            const message = await modalRef.result;
            this.message_ = tryParseJSON(message, undefined);

            this.redirectToTargetRoute();
        } catch {
            // do nothing
        }
    }

    public redirectToTargetRoute() {
        const dashboard = this.message_ as TenantDashboard;
        dashboard.title = dashboard.title ?? this.translationService.translate('defaults.generatedByAI');
        dashboard.id = dashboard.id ?? getRandomId();

        if (dashboard?.widgets?.length) {
            dashboard.widgets = dashboard.widgets.map((widget) => {
                return {
                    ...widget,
                    configuration: typeof widget.configuration !== 'string' ? JSON.stringify(widget.configuration) : widget.configuration,
                };
            });
        }

        const errors = validateJSON(dashboard, { widgets: [{ configuration: '', title: '', widgetType: '' }] });

        if (errors?.length) {
            console.error(errors);
            return this.router.navigate(['error'], { queryParams: { id: 'notValidMessage' } });
        }

        return this.router.navigate(['ai-assistant', 'dashboards', dashboard.id]);
    }
}
